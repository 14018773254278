import React, { FC } from 'react';
import classNames from 'classnames';

import { InjectedClassNameProps, InjectedDataHookProps } from '../../../types';
import styles from './RepositionButton.scss';
import { RepositionIcon } from './RepositionIcon';

type InjectedProps = InjectedClassNameProps & InjectedDataHookProps;

interface RepositionButtonProps extends InjectedProps {
  text: string;
  onClick?: () => void;
}

export const RepositionButton: FC<
  React.PropsWithChildren<RepositionButtonProps>
> = ({ dataHook, className, text, onClick }) => {
  return (
    <button
      data-hook={dataHook}
      className={classNames(styles.button, className)}
      onClick={onClick}
    >
      <RepositionIcon />
      <span className={styles.text}>{text}</span>
    </button>
  );
};
